import { mapGetters, mapState } from 'vuex';
import { mapGettersMutations } from '@/utils/vuex';
import Vue from 'vue';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

export default function resourceListFactory(
  storeModule,
  filters = [],
  resourceName = 'ресурсов',
  options = {
    createMutations: true,
    autoWatch: true,
  },
) {
  const filtersGettersMutations = options.createMutations
    ? Object.fromEntries(filters.map(item => [item, item]))
    : {};
  const queryFields = [
    'limit',
    'page',
    'orderBy',
    'orderType',
    'search',
    ...filters,
  ];

  return Vue.extend({
    computed: {
      ...mapGetters(storeModule, [
        'listIsLoading',
        'listItems',
        'listItem',
        'listTotal',
        'listTotalPages',
        'listShowPagination',
        'listSearch',
      ]),

      ...mapState(storeModule, ['listQuery']),

      ...mapGettersMutations(storeModule, {
        listCurrentPage: 'listCurrentPage',
        listLimit: 'listLimit',
        listOrderBy: 'listOrderBy',
        listOrderType: 'listOrderType',
        ...filtersGettersMutations,
      }),
    },

    async created() {
      await this.beforeFirstFetchList();
      const query = decodeQueryObject(this.$route.query, queryFields);

      await this.fetchList(query);

      if (!options.autoWatch) return;
      this.$watch('listQuery', (newValue, oldValue) => {
        const newQuery = filterQueryObject(newValue, queryFields);
        const oldQuery = filterQueryObject(oldValue, queryFields);

        if (isEqual(newQuery, oldQuery)) return;
        this.updateListRoute(newQuery);
      });
    },

    methods: {
      beforeFirstFetchList() {},
      async fetchList(query) {
        try {
          await this.$store.dispatch(storeModule + '/fetchList', query);
          this.$notify({
            group: 'note',
            title: `Найдено ${resourceName}: ${this.listTotal}`,
          });
        } catch (error) {
          this.$notify({
            group: 'error',
            title: `Произошла ошибка при загрузке ${resourceName}`,
            text: error.message,
          });
        }
      },
      async updateListRoute(query) {
        await this.$router
          .push({
            name: this.$route.name,
            query,
          })
          .catch(() => {});
        this.fetchList();
      },

      querySearchList: debounce.call(
        this,
        function (value) {
          this.$store
            .dispatch(storeModule + '/querySearchList', value)
            .finally(() => this.$refs.search.focus());
        },
        1000,
      ),
    },
  });
}

function filterQueryObject(query, queryFields) {
  return Object.fromEntries(
    Object.entries(query).filter(item => queryFields.includes(item[0])),
  );
}

function decodeQueryObject(query, queryFields) {
  query = Object.entries(query)
    .map(([key, value]) => {
      if (!queryFields.includes(key)) return;

      if (value === 'true') value = true;
      else if (value === 'false') value = false;
      else if (isNumeric(value)) value = +value;
      return [key, value];
    })
    .filter(item => item);

  return query.length !== 0 ? Object.fromEntries(query) : null;
}

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}
